import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aside-menu flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside aside-hoverable", [
      _ctx.asideTheme === 'light' && 'aside-light',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_KTMenu)
    ])
  ], 2))
}